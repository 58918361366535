import CryptoJS from 'crypto-js';

export const getPhoneCodes = () => {
    return [
        {
            id: 2,
            country: 'BR',
            code: 55,
            phoneMask: '(##) #####-####'
        },
        {
            id: 7,
            country: 'UY',
            code: 598,
            phoneMask: '### ### ###'
        }
    ];
};

export const removeMaskFromPhoneNumber = (phoneWithMask) => {
    return phoneWithMask.replace(/\D/g, '');
}

export const getIdentificationTypes = () => {
    return [
        {
            id: 1,
            country: 'BR',
            value: 'CPF'
        },
        {
            id: 7,
            country: 'UY',
            value: 'DNIC'
        }
    ];
};

export const validateMonth = (month) => {
    return (
        month === '01' ||
        month === '02' ||
        month === '03' ||
        month === '04' ||
        month === '05' ||
        month === '06' ||
        month === '07' ||
        month === '08' ||
        month === '09' ||
        month === '10' ||
        month === '11' ||
        month === '12'
    );
};

export const typeOnlyNumber = ($event) => {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
    }
};

export function formatPrice (price) {
    return `$${price.toFixed(2)}`;
}

export const hashData = (data, secret) => {
    var b64 = CryptoJS.AES.encrypt(data.toString(), secret).toString();
    var e64 = CryptoJS.enc.Base64.parse(b64);
    var eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
}

export const unhashData = (data, secret) => {
    var reb64 = CryptoJS.enc.Hex.parse(data.toString());
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, secret);
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
}
